/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Tabs } from "antd";
import styles from "../styles/CobuyerCosellerList.module.css";
import EditCorpOrClient from "./EditCorpOrClient";
const { TabPane } = Tabs;
function CobuyerCosellerList({ clientType, contractAuthKey, currentUrlId, rootDocData, sourceType }) {
  return (
    <>
      <div className={styles.contentDiv} id={"corpSignersList"}>
        {sourceType === "NORMAL" ? <EditCorpOrClient sourceType={sourceType} source={"CLIENTS"} currentUrlId={currentUrlId} clientType={clientType} rootDocData={rootDocData} contractAuthKey={contractAuthKey} /> : <EditCorpOrClient sourceType={sourceType} source={"CLIENTS"} currentUrlId={currentUrlId} clientType={clientType} rootDocData={rootDocData} contractAuthKey={contractAuthKey} />}
      </div>
    </>
  );
}

export default CobuyerCosellerList;
