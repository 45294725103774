import { transactionLaneConstants } from "./action-types";
import { transactionLaneDataApi } from "../utils/api";
import dotEnv from "dotenv";
import { message } from "antd";
import { offerTableHandle } from "../../OfferTable/state/actions";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { contractDataHandle } from "../../CreateOffer/state/actions";
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
/**
 * All Actions for contract Page
 */

/**
 * @param {string}
 */

const getTransactionLaneError = (error) => {
  return {
    type: transactionLaneConstants.GET_TRANSACTION_LANE_IN_RTD_FAILURE,
    getTransactionLaneError: error,
    getTransactionLaneLoading: false,
  };
};

const getTranscactionLaneSucess = (response) => {
  return {
    type: transactionLaneConstants.GET_TRANSACTION_LANE_IN_RTD_SUCCESS,
    getTransactionLaneData: response.data.info,
    getTransactionLaneLoading: false,
  };
};

const getTransactionLaneLoading = (responce) => {
  return {
    type: transactionLaneConstants.GET_TRANSACTION_LANE_IN_RTD_LOADING,
    getTransactionLaneLoading: responce,
  };
};

const getTransactionLaneMethod = (id, role,userAuthKey) => {
  
  return (dispatch, getState) => {
    dispatch(getTransactionLaneLoading(true));
    transactionLaneDataApi
      .transactionLaneApi(id, role,userAuthKey)
      .then((response) => {
        dispatch(getTranscactionLaneSucess(response));
        dispatch(getTransactionLaneLoading(false));
      })
      .catch((error) => {
        dispatch(getTransactionLaneError(error));
      });
  };
};

const getDraftDocumentsFailure = (error) => {
  return {
    type: transactionLaneConstants.DRAFT_DOCUMENTS_FAILURE,
    getDraftFailure: error,
    getDraftLoading: false,
  };
};

const getDraftDocumentsSucess = (response) => {
  return {
    type: transactionLaneConstants.DRAFT_DOCUMENTS_SUCCESS,
    getDraftDocumentData: response,
    getDraftLoading: false,
  };
};

const getDraftDocumentData = (data, source, history) => {
  return (dispatch, getState) => {
    transactionLaneDataApi
      .draftDocumetnApi(data)
      .then((response) => {
        if (source && source === "Print") {
          history.push(`/contractDocument/${response.data.info._id}?sameOrigin=true`)
        // }
        // else if(source && source === 'TO'){
        //   history.push(`/closingSheet/${response.data.info._id}?sameOrigin=true`)
        } else {
          history.push(`/contractDocument/${response.data.info._id}?sameOrigin=true`)
        }
      })

      .catch((error) => {
        // error:""
        dispatch(getDraftDocumentsFailure(error));
      });
  };
};

const updateDocumentLoading = (responce) => {
  return {
    type: transactionLaneConstants.UPDATE_DOCUMENT_LOADING,
    updateDocumentLoading: responce,
  };
};

const updateDocumentSuccess = (response) => {
  return {
    type: transactionLaneConstants.UPDATE_DOCUMENT_SUCCESS,
    updateDocumentSucess: true,
    updateDocumentFailure: false,
  };
};

const updatetDocumentFailure = (error) => {
  return {
    type: transactionLaneConstants.UPDATE_DOCUMENT_FAILURE,
    updateDocumentFailure: error,
    updateDocumentLoading: false,
  };
};

const updateDocument = ({documentId, data, client,userAuthKey,offerId, emailSignatureId, emailMessage}) => {

  return (dispatch, getState) => {
    dispatch(contractStoreHandle.setTransparentDivLoading({
      loadingStatus: true,
    }));
    transactionLaneDataApi
      .updateDocumentApi({documentId, data, userAuthKey, emailSignatureId, emailMessage})
      .then((response) => {
        message.success(response.data.info?.message || "Document Sent Successfully!");
        dispatch(getTransactionLaneMethod(offerId,client,userAuthKey))
      })
      .then(() =>  dispatch(contractStoreHandle.setTransparentDivLoading({
        loadingStatus: false,
      })))
      .catch((error) => {
        dispatch(contractStoreHandle.setTransparentDivLoading({
          loadingStatus: false,
        }));
        message.error(error?.response?.data?.message || "Failed to send document!");
        // dispatch(updatetDocumentFailure(error));
      });
  };
};

// Email actions
const sendEmailLoading = () => {
  return {
    type: transactionLaneConstants.SEND_EMAIL_LOADING,
  };
};
const sendEmailError = (error) => {
  return {
    type: transactionLaneConstants.SEND_EMAIL_FAILURE,
    sendEmailError: error,
  };
};

const sendEmailSuccess = (response) => {
  return {
    type: transactionLaneConstants.SEND_EMAIL_SUCCESS,
    sendEmailData: response.data.info,
  };
};

const sendEmailToClients = (data) => {
  return (dispatch, getState) => {
    let userAuthKey = getState()?.offerTable?.offerTableAuthKey;
    dispatch(sendEmailLoading());
    transactionLaneDataApi
      .sendEmailToClientRequest(data,userAuthKey)
      .then((response) => {
        dispatch(sendEmailSuccess(response));
        message.success("Document Sent Succesfully!");
      })
      .catch((error) => {
        dispatch(sendEmailError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while sending email to clients.Please try again later!");
      });
  };
};

const addEmailFunLoading = () => {
  return {
    type:transactionLaneConstants.ADD_EMAIL_LOADING,
  }
}

const addEmailFunError = () => {
  return {
    type:transactionLaneConstants.ADD_EMAIL_ERROR,
  }
}

const addEmailFunSuccess = () => {
  return {
    type:transactionLaneConstants.ADD_EMAIL_SUCCESS,
  }
}

const addEmailFun = ({data, userAuthKey, source, setEnterEmailModal,enterEmailModal,documentId,setIsEmailModalVisible, isEmailModalVisible,form, setCallPropertyDocsApi}) => {
  return (dispatch, getState) => {
    dispatch(addEmailFunLoading())
    const offerTableAuthKey = getState()?.offerTable?.offerTableAuthKey;
    const offerTablePropertyId = getState()?.offerTable?.offerTablePropertyId;
    transactionLaneDataApi.addEmailRequest(["CONTACT_SELLING_RTD", "CONTACT_BUYING_RTD", "TRANSACTION_CALENDAR"].includes(source) ? userAuthKey : offerTableAuthKey, data)
    .then((response) => {
      dispatch(addEmailFunSuccess())
      if(!response?.data?.info?.showClients){
        message.success("Email added successfully");
        // if(setIsEmailModalVisible){
        //   setIsEmailModalVisible({ ...isEmailModalVisible, isEmailModalOpen: false, sendMultipleDoc: false });
        // }
        if(source === "SHARE_INDIVIDUAL_OFFER"){
          dispatch(offerTableHandle.getOfferTable(offerTableAuthKey,offerTablePropertyId))
        }
        if(source === 'TRANSACTION_CALENDAR'){
          setCallPropertyDocsApi(true);
        }
        form?.resetFields();
      }else{
        if(setEnterEmailModal){
          setEnterEmailModal({...enterEmailModal,data:{showClients:response?.data?.info?.showClients, allClients: response?.data?.info?.allClients}})
        }
      }

      // if(source==="CONTACT_SELLING_RTD" || source==="CONTACT_BUYING_RTD"){
      //   dispatch(contractDataHandle.getPersistDeatailsDataOnly(documentId,userAuthKey))
      // }else{
      //   dispatch(offerTableHandle.getOfferTable(offerTableAuthKey,offerTablePropertyId))
      // }
    })
    .catch((error) => {
      dispatch(addEmailFunError())
      message.error(error?.response?.data?.message || "We encountered an issue while adding email.Please try again later!");
    })
  }
}
// Dora docs
const getDoraDocumentsLoading = () => {
  return {
    type: transactionLaneConstants.GET_DORA_DOCUMENTS_LOADING,
  };
};
const getDoraDocumentsError = (error) => {
  return {
    type: transactionLaneConstants.GET_DORA_DOCUMENTS_FAILURE,
    getDoraDocumentsError: error,
    getDoraDocumentsLoading: false,
  };
};

const getDoraDocumentsSuccess = (response) => {
  return {
    type: transactionLaneConstants.GET_DORA_DOCUMENTS_SUCCESS,
    getDoraDocumentsData: response,
    getDoraDocumentsLoading: false,
  };
};

const getDoraDocumentsData = (data) => {
  return (dispatch) => {
    dispatch(getDoraDocumentsLoading());
    transactionLaneDataApi
      .getNestedDoraDocuments(data)
      .then((response) => {
        dispatch(getDoraDocumentsSuccess(response.data.info));
      })
      .catch((error) => {
        dispatch(getDoraDocumentsError(error));
      });
  };
};

// get emailtemplate List
const get_Email_Template_Loading = () => {
  return {
    type: transactionLaneConstants.GET_EMAIL_TEMPLATE_LOADING,
  };
};
const get_Email_Template_Error = (error) => {
  return {
    type: transactionLaneConstants.GET_EMAIL_TEMPLATE_FAILURE,
  };
};
const get_Email_Template_Success = (response) => {
  return {
    type: transactionLaneConstants.GET_EMAIL_TEMPLATE_SUCCESS,
    getEmail_Templatedata: response.data.info,
  };
};

const getEmail_Template = (userAuthKey) => {
  return (dispatch) => {
    dispatch(get_Email_Template_Loading());
    transactionLaneDataApi
      .getemailtemplateRequest(userAuthKey)
      .then((response) => {
        dispatch(get_Email_Template_Success(response));
      })
      .catch((error) => {
        dispatch(get_Email_Template_Error(error));
      });
  };
};
// Dradocs URl shorting
const doraDocsUrlShorthandFailure = (error) => {
  return {
    type: transactionLaneConstants.DORADOCS_SHORTHAND_URL_FAILURE,
    doraDocsUrlShorthandLoading: false,
    doraDocsUrlShorthandError: error,
  };
};

const doraDocsUrlShorthandSuccess = (response) => {
  return {
    type: transactionLaneConstants.DORADOCS_SHORTHAND_URL_SUCCESS,
    doraDocsUrlShorthandData: response,
    doraDocsUrlShorthandLoading: false,
  };
};

const doraDocsUrlShorthandLoading = (response) => {
  return {
    type: transactionLaneConstants.DORADOCS_SHORTHAND_URL_LOADING,
    doraDocsUrlShorthandLoading: response,
  };
};

const doraDocsUrlShorthandApiCalling = (data,userAuthKey, history, source) => {
  return (dispatch, getState) => {
    dispatch(doraDocsUrlShorthandLoading(true));
    transactionLaneDataApi
      .urlShorthandApi(data,userAuthKey)
      .then((response) => {
        dispatch(doraDocsUrlShorthandSuccess(response.data.info._id));
        history.push(`/contractDocument/${response.data.info._id}?sameOrigin=true`)
      })
      .catch((error) => {
        dispatch(doraDocsUrlShorthandFailure(error));
      });
  };
};

//update && Email phone
const updateEmailError = (error) => {
    return {
      type: transactionLaneConstants.UPDATE_EMAIL_FAILURE,
      updateEmailError: error,
      updateEmailLoading: false,
    };
  };

  const updateEmailSuccess = (response) => {
    return {
      type: transactionLaneConstants.UPDATE_EMAIL_SUCCESS,
      updateEmailDetails: response.data.info,
      updateEmailLoading: false,
    };
  };

  const updateEmail = (values,personId,offerTableAuthKey,offerTablePropertyId,source) => {
    return (dispatch, getState) => {
        dispatch(addEmailFunLoading())
        transactionLaneDataApi
        .updateEmailRequest(values,personId,offerTableAuthKey)
        .then((response) => {
          // dispatch(updateEmailSuccess(response));
          dispatch(addEmailFunSuccess())
          if(offerTablePropertyId){
            dispatch(offerTableHandle.getOfferTable(offerTableAuthKey,offerTablePropertyId))
          }
          message.success("Updated Successfully!", 2);
        })
        .catch((error) => {
          message.error(
            error?.response?.data?.message || "We encountered an issue while updating email.Please try again later!"
          );
          dispatch(updateEmailError(error));
        });
    };
  };

  //update Transaction Email
  const updateTransactionEmailLoading = (error) => {
    return {
      type: transactionLaneConstants.UPDATE_EMAIL_FAILURE,
      updateEmailError: error,
      updateEmailLoading: false,
    };
  };

const updateTransactionEmailError = (error) => {
    return {
      type: transactionLaneConstants.UPDATE_EMAIL_FAILURE,
      updateEmailError: error,
      updateEmailLoading: false,
    };
  };

  const updateTransactionEmailSuccess = (response) => {
    return {
      type: transactionLaneConstants.UPDATE_EMAIL_SUCCESS,
      updateEmailDetails: response.data.info,
      updateEmailLoading: false,
    };
  };

  const updateTransactionEmail = (values,sellerDetail,offerId,offerTableAuthKey,offerTablePropertyId) => {
      return (dispatch, getState) => {
        dispatch(updateTransactionEmailLoading());
        transactionLaneDataApi
        .updateTransactionEmailRequest(values.email,sellerDetail,offerId,offerTableAuthKey)
        .then((response) => {
            dispatch(updateTransactionEmailSuccess(response));
            dispatch(offerTableHandle.getOfferTable(offerTableAuthKey,offerTablePropertyId))
          message.success("Updated Successfully!", 2);
        }).catch((error) => {
            dispatch(updateTransactionEmailError(error));
        });
    };
  };

  const getSharedDocsLoading = () => {
    return {
      type: transactionLaneConstants.GET_SHARED_DOCS_LOADING,
    };
  };
  const getSharedDocsError = (error) => {
    return {
      type: transactionLaneConstants.GET_SHARED_DOCS_FAILURE,
    };
  };

  const getSharedDocsSuccess = (response) => {
    return {
      type: transactionLaneConstants.GET_SHARED_DOCS_SUCCESS,
      getSharedDocsData: response.data.info,
    };
  };

  const getSharedDocsFun = ({offerId, userAuthKey}) => {
    return (dispatch, getState) => {
      dispatch(getSharedDocsLoading());
      transactionLaneDataApi
        .getSharedDocsRequest({offerId, userAuthKey})
        .then((response) => {
          dispatch(getSharedDocsSuccess(response));
        })
        .catch((error) => {
          dispatch(getSharedDocsError(error));
          message.error(error?.response?.data?.message || "We encountered an issue while fetching shared document data. Please try again later!");
        });
    };
  };
  const getIndividualTransactionSuccess = (response) => {
    return {
      type: transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_SUCCESS,
      getIndividualTransactionData: response.data.info
    };
  };
  const getIndividualTransactionError = (error) => {
    return {
      type: transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_FAILURE,
      errorMsg: error?.response?.data?.message || "Failed to fetch individual transaction data!",
    };
  };
  const getIndividualTransaction = ({offerId, propertyId, setLoading, source, unmounted, delegateSide, delegateOwnerId,userAuthKey}) => {
    return (dispatch, getState) => {
      setLoading(true);
      transactionLaneDataApi
        .individualTransactionApi({offerId, propertyId, source, delegateSide, delegateOwnerId,userAuthKey})
        .then((response) => {
          if(!unmounted.value){
            dispatch(getIndividualTransactionSuccess(response));
            setLoading(false);
          }
        })
        .catch((error) => {
          if(!unmounted.value){
            dispatch(getIndividualTransactionError(error));
            message.error(error?.response?.data?.message || "Failed to fetch individual transaction data!");
            setLoading(false);
          }
        });
    };
  };

const deletePropertyContractDocs = ({ payloadData }) => {
  return (dispatch, getState) => {
    const { offerId, role, token } = payloadData
    transactionLaneDataApi
      .deletePropertyContractDocsRequest({ payloadData })
      .then((response) => {
        if (response) {
          message.success(response?.data?.info?.message || "Document Deleted Successfully!");
          dispatch(getTransactionLaneMethod(offerId, role, token))
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error occured while deleting document!");
        dispatch(getTransactionLaneError(error));
      });
  };
};

export const transactionLaneDataHandle = {
  sendEmailToClients,
  getTransactionLaneMethod,
  getDraftDocumentData,
  updateDocument,
  getTranscactionLaneSucess,
  getDoraDocumentsData,
  getEmail_Template,
  doraDocsUrlShorthandApiCalling,
  updateEmail,
  updateTransactionEmail,
  addEmailFun,
  getSharedDocsFun,
  getIndividualTransaction,
  deletePropertyContractDocs
};
