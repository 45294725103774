/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Button, Col, Form, List, Modal, Row, Tooltip, message, Input, Avatar, Collapse } from "antd";
import { UserAddOutlined, SearchOutlined, UserOutlined, DeleteTwoTone, ExclamationCircleOutlined, CaretRightOutlined, PlusCircleFilled } from "@ant-design/icons";
import { ClientList } from "./ClientList";
import { EmptyData } from "../../Common/components/EmptyData";
import { useMediaQuery } from "react-responsive";
import AddClient from "./AddClient";
import buildSignatureBlocks from "../features/helpers/buildSignatureBlocks";
import styles from "../styles/CobuyerCosellerList.module.css";
import { v4 as uuidv4 } from "uuid";
import ManualAddSeller from "./ManualAddSeller";
import { generateFullNameFromObj, generateInitialsFromObj } from "../../Common/utils/universalFuntions";
import EachCorpSigner from "./EachCorpSigner";
import { documentAction } from "../../DocumentSection/state/actions";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { checkOnlySignersAllowedToEdit } from "../features/helperFunctions";
const { confirm } = Modal;
const { Panel } = Collapse;
const EditCorpOrClient = ({ sourceType, source, clientType, contractAuthKey, currentUrlId, rootDocData }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const [clientsArray, setClientsArray] = useState([]);
  const [currentlySelectedData, setCurrentlySelectedData] = useState([]);
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [addPersons, setAddPerson] = useState(false);
  const [addCorps, setAddCorp] = useState(false);
  const [trustName, setTrustName] = useState("");
  const [getClientData, setClientData] = useState([]);
  const [visible, setVisible] = useState(false);

  const onlySignersAllowedToEdit = checkOnlySignersAllowedToEdit(rootDocData);

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    if (clientType) {
      setClientsArray(localData[clientType] || []);
    }
  }, [clientType]);

  const handleClientSelected = (selectedClient) => {
    if (!selectedClient.email) {
      message.error("Please add the client's email to proceed!");
      return;
    }

    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    const clients = localData[clientType] || [];

    if (clients.length >= 10) {
      message.error(`Maximum number of ${clientType} already added.`);
      return;
    }
    if (currentlySelectedData?.isCorp && currentlySelectedData.signers.some((el) => el.personId === selectedClient._id)) {
      message.error(`Client already exists in the Signers list.`);
      return;
    } else if (!currentlySelectedData?.isCorp && clients.some((client) => client.personId === selectedClient._id)) {
      message.error(`Client already exists in the ${clientType} list.`);
      return;
    }
    let clientInfo;
    if (currentlySelectedData?.isCorp) {
      clientInfo = { fullName: generateFullNameFromObj(selectedClient), personId: selectedClient._id };
    } else {
      clientInfo = { fullName: generateFullNameFromObj(selectedClient), personId: selectedClient._id, isCorp: false, signers: [] };
    }
    if (currentlySelectedData?.isCorp) {
      let newDataIndex = clients.findIndex((el) => el.key === currentlySelectedData.key);

      if (newDataIndex !== -1) {
        localData[clientType] = [...clients.slice(0, newDataIndex), { ...clients[newDataIndex], signers: [...(clients[newDataIndex].signers || []), clientInfo] }, ...clients.slice(newDataIndex + 1)];
      }
    } else {
      localData[clientType] = [...clients, clientInfo];
    }
    setClientsArray(localData[clientType]);
    const sigBlock = buildSignatureBlocks([clientInfo], clientType, rootDocData);
    const sigKey = clientType === "Buyers" ? "BUYER" : "SELLER";
    document.querySelectorAll(`[data-signature-block="${sigKey}"]`)?.forEach((element) => {
      element.innerHTML = sigBlock;
    });
    localStorage.setItem(currentUrlId, JSON.stringify(localData));

    if (contractAuthKey) {
      dispatch(contractStoreHandle.autoSaveContract({ currentUrlId, contractAuthKey, valueObj: { [clientType]: localData[clientType] } }));
    } else {
      // dispatch(documentAction.setpdfImportState({ CurrentPdfImportData: localData }));
      dispatch(documentAction.setpdfImportState({ [clientType]: localData[clientType] }));
    }
  };

  const handleDelete = (element, type, item) => {
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    if (localData[clientType]) {
      if (sourceType === "NORMAL") {
        if (type === "CORP") {
          handleCorpDelete(element);
          return;
        } else if (type === "SIGNERS") {
          let newDataIndex = localData[clientType]?.findIndex((el) => el.key === element.key);
          if (newDataIndex !== -1) {
            localData[clientType][newDataIndex].signers = localData[clientType][newDataIndex]?.signers?.filter((signer) => signer.personId !== item?.personId);
          }
          // console.log("element.personId", element.personId);
          // console.log("newDataIndex", newDataIndex, localData[clientType][newDataIndex].signers);
        } else {
          localData[clientType] = localData[clientType]?.filter((el) => el.personId !== element.personId);
        }
      } else {
        if (type === "CORP") {
          handleCorpDelete(element);
          return;
        } else if (type === "SIGNERS") {
          let newDataIndex = localData[clientType]?.findIndex((el) => el.key === element.key);
          // console.log(localData[clientType][newDataIndex].signers, "testing above one else");
          if (newDataIndex !== -1) {
            localData[clientType][newDataIndex].signers = localData[clientType][newDataIndex]?.signers?.filter((signer) => {
              if (item.key) {
                // console.log("signer.key:", signer.key);
                return signer.key !== item.key;
              } else {
                return signer.personId !== item.personId;
              }
            });
            // console.log(localData[clientType][newDataIndex].signers, "testing inside else");
          }
        } else {
          localData[clientType] = localData[clientType]?.filter((el) => {
            if (element.personId) {
              // console.log("el.personId:", el.personId);
              return el.personId !== element.personId;
            } else {
              // console.log("el.key:", el.key);
              return el.key !== element.key;
            }
          });
        }
      }
      handleDataChange(localData, clientType);
    }
  };

  let handleDataChange = (localData, clientType) => {
    setClientsArray(localData[clientType]);
    localStorage.setItem(currentUrlId, JSON.stringify(localData));
    if (contractAuthKey) {
      dispatch(contractStoreHandle.autoSaveContract({ currentUrlId, contractAuthKey, valueObj: { [clientType]: localData[clientType] } }));
    } else {
      // dispatch(documentAction.setpdfImportState( { CurrentPdfImportData: localData }));
      dispatch(documentAction.setpdfImportState({ [clientType]: localData[clientType] }));
    }
  };
  let handleCancel = () => {
    setShowClientListModal(false);
  };

  const handleCorpDelete = (element = {}) => {
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    confirm({
      title: `Are you sure you want to remove ${element.fullName} from this form?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: This will also remove all the signers associated with it.",
      onOk() {
        localData[clientType] = localData[clientType]?.filter((el) => el.key !== element.key);
        handleDataChange(localData, clientType);
      },
      onCancel() {},
    });
  };
  const handleAddSigners = (element) => {
    setShowClientListModal(true);
    setCurrentlySelectedData(element);
    setAddCorp(!addCorps);
  };

  const handleCorpAdd = () => {
    setTrustName("");
    let corpInfo = {
      fullName: trustName?.trim(),
      isCorp: true,
      key: uuidv4(),
      signers: [],
    };
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    if (clientType) {
      localData[clientType] = [...(localData[clientType] || []), corpInfo];
    }
    handleDataChange(localData, clientType);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCorpAdd();
    }
  };

  return (
    <>
      {showClientListModal ? (
        <Modal width={600} style={{ height: "600px" }} visible={showClientListModal} title={sourceType === "MANUAL_ADD_CLIENTS" ? `Add Signers for ${currentlySelectedData.fullName}` : ""} onOk={handleCancel} mask={true} closable={true} onCancel={handleCancel} footer={null}>
          {sourceType === "MANUAL_ADD_CLIENTS" ? <ManualAddSeller currentUrlId={currentUrlId} contractAuthKey={contractAuthKey} sourceType={sourceType} source={"CORP"} currentlySelectedData={currentlySelectedData} clientType={clientType} clientsArray={clientsArray} setClientsArray={setClientsArray} handleCorpAdd={handleCorpAdd} trustName={trustName} setTrustName={setTrustName} setCurrentlySelectedData={setCurrentlySelectedData} /> : <ClientList onAdd={handleClientSelected} accessTokan={contractAuthKey} handleCancel={handleCancel} />}
        </Modal>
      ) : null}
      {visible ? <AddClient clientType={clientType} visible={visible} setVisible={setVisible} setClientData={setClientData} accessTokan={contractAuthKey} contractClientType={clientType} /> : null}

      {sourceType === "MANUAL_ADD_CLIENTS" && source === "CLIENTS" ? <ManualAddSeller currentUrlId={currentUrlId} contractAuthKey={contractAuthKey} sourceType={sourceType} source={source} clientType={clientType} clientsArray={clientsArray} setClientsArray={setClientsArray} handleCorpAdd={handleCorpAdd} trustName={trustName} setTrustName={setTrustName} setCurrentlySelectedData={setCurrentlySelectedData} /> : null}
      {
        <>
          {sourceType === "NORMAL" && source === "CLIENTS" && (
            <div className={styles.personheading}>
              <text>Persons</text>
              <PlusCircleFilled
                style={{
                  fontSize: "25px",
                  fill: "#085191",
                  color: "#085191",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  setAddPerson(!addPersons);
                }}
              />
            </div>
          )}
          <Form
            layout="vertical"
            initialValues={{}}
            // onFinish={onFinish}
            autoComplete="off"
          >
            {sourceType === "NORMAL" && source === "CLIENTS" ? (
              <>
                {/* <Col style={{ margin: "0px", padding: "0px", width: "100%", overflowX: "auto" }}> */}

                {onlySignersAllowedToEdit
                  ? null
                  : addPersons && (
                      <Form.Item className={styles.formItem} style={{ marginTop: "10px", display: "flex" }} name="buyer">
                        <div className={styles.inputBoxDiv}>
                          <Input
                            placeholder="Select a person"
                            onClick={(element) => {
                              setShowClientListModal(true);
                              setCurrentlySelectedData(element);
                            }}
                          />

                          <Button
                            style={{ marginLeft: "-10px" }}
                            onClick={(element) => {
                              setShowClientListModal(true);
                              setCurrentlySelectedData(element);
                            }}
                          >
                            <SearchOutlined style={{ fontSize: "18px" }} />
                          </Button>
                          <Col span={1} style={{ alignSelf: "center" }}>
                            <Tooltip placement="top" title={"Create Contact"}>
                              <UserAddOutlined onClick={() => setVisible(true)} style={{ fontSize: "18px", color: "#5959dcd9" }} />
                            </Tooltip>
                          </Col>
                        </div>
                      </Form.Item>
                    )}
                {clientsArray?.length > 0 ? (
                  <Form.Item>
                    <Row>
                      <Col className={styles.selectedRowsOuterDivParent}>
                        <div className={styles.selectedRowsOuterDiv}>
                          <List
                            dataSource={clientsArray.filter((ele) => !ele.isCorp)}
                            renderItem={(item) => {
                              const intials = generateInitialsFromObj(item);
                              return (
                                <List.Item
                                  key={item?._id}
                                  style={{
                                    margin: isMobile ? "-5px 0px" :"5px 0px",
                                    width: "90%",
   
                                  }}
                                >
                                  <List.Item.Meta
                                    avatar={
                                      intials ? (
                                        <Avatar size={45} style={{ background: "#085191" }}>
                                          {intials}
                                        </Avatar>
                                      ) : (
                                        <Avatar size={45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                                      )
                                    }
                                    title={<span style={{ fontSize: "15px", display: "inline-block", verticalAlign: "middle", lineHeight: "45px", whiteSpace: "nowrap", maxWidth: isMobile ? "160px" : "auto", minWidth: isMobile ? "160px" : "70%", overflow: "hidden", textOverflow: "ellipsis", textTransform: "capitalize" }}> {item?.fullName || ""}</span>}
                                  />
                                  {onlySignersAllowedToEdit ? null : (
                                    <span onClick={() => handleDelete(item, "CLIENT")} style={{ cursor: "pointer" }}>
                                      <DeleteTwoTone style={{ fontSize: "medium", marginLeft: "100%" }} />
                                    </span>
                                  )}
                                </List.Item>
                              );
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <EmptyData />
                  </div>
                )}
              </>
            ) : null}
            <div className={styles.corpheading}>
              <text>Corporation/Trust/Other</text>
              <PlusCircleFilled
                style={{
                  fontSize: "25px",
                  fill: "#085191",
                  color: "#085191",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  setAddCorp(!addCorps);
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", whiteSpace: "nowrap", alignItems: "stretch" }}>
              {onlySignersAllowedToEdit
                ? null
                : addCorps && (
                    <Form.Item name="trustee">
                      <div style={{ display: "flex", gap: "10px", marginTop: "10px" ,marginBottom:"-25px"}}>
                        <Input data-no-auto-save data-not-autofill-values allowClear placeholder="Enter Corporation/Trust Name" onKeyPress={handleKeyPress} name="trustee" value={trustName && trustName.length > 0 ? trustName : ""} onChange={(e) => setTrustName(e.target.value)} />

                        <Button type="primary" onClick={handleCorpAdd} disabled={!trustName}>
                          Add
                        </Button>
                      </div>
                    </Form.Item>
                  )}
              <div style={{ width: "100%",overflow:"hidden" }}>
                <div style={{ background: "white" }}>
                  {clientsArray
                    ?.filter((ele) => ele.isCorp)
                    .map((ele, ind) => (
                      <div
                        key={ind}
                        style={{
                          marginTop: "10px",
                          borderBottom: "1px solid #f0f0f0",
                        }}
                      >
                        {/* Corporation Header */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // maxWidth: isMobile ? "300px" : "auto",
                            // minWidth: isMobile ? "200px" : "99%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "20px",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "18px",
                                textTransform: "capitalize",
                                maxWidth: isMobile ? "160px" : "auto",
                                minWidth: isMobile ? "160px" : "70%",
                                wordBreak: "break-word", // Ensures long words wrap to the next line
                                whiteSpace: "normal", // Allows wrapping of text
                              }}
                            >
                              {ele.fullName || ""}
                            </span>
                            <div style={{ display: "flex" }}>
                              <div style={{ marginRight: "15px" }}>
                                <Tooltip title="Add Signers">
                                  <PlusCircleFilled
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddSigners(ele, sourceType);
                                    }}
                                    style={{
                                      fontSize: "20px",
                                      fill: "#085191",
                                      color: "#085191",
                                      marginTop: "6px",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              {onlySignersAllowedToEdit ? null : (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(ele, "CORP");
                                  }}
                                >
                                  <Tooltip title="Delete Corporation/Trust">
                                    <DeleteTwoTone
                                      style={{
                                        fontSize: "medium",
                                        marginTop: "8px",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Signers List */}
                        {(ele.signers || []).length > 0 && (
                          <List
                            dataSource={ele.signers}
                            renderItem={(item) => {
                              const initials = generateInitialsFromObj(item);
                              return <EachCorpSigner key={item?._id} item={item} ele={ele} initials={initials} isMobile={isMobile} clientType={clientType} currentUrlId={currentUrlId} handleDelete={handleDelete} handleDataChange={handleDataChange} />;
                            }}
                          />
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Form>
        </>
      }
    </>
  );
};

export default EditCorpOrClient;
